<template>
    <b-container fluid>
        <b-row v-if="!isAllowed">
            <div class="box-message col-md-12">
                <div class="message-body">
                    <p class="pb-2 pt-2"><i class="ri-alert-fill mr-2"></i>{{ $t('externalUserIrrigation.noPrivilegeFeatureMessage') }}</p>
                </div>
            </div>
        </b-row>
        <b-row v-else>
            <b-col md="12">
                <iq-card>
                    <template v-slot:body>
                        <form-search :data="searchAttributes" @returned-data="searchData"/>
                    </template>
                </iq-card>
            </b-col>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('external_sidebar.my_daily_task') }} </h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + 1) }}
                                        </template>
                                        <template v-slot:cell(meter_reading_before)="data">
                                            {{ $n(data.item.meter_reading_before) }}
                                        </template>
                                        <template v-slot:cell(meter_reading_after)="data">
                                            {{ $n(data.item.meter_reading_after) }}
                                        </template>
                                        <template v-slot:cell(pump_running_time)="data">
                                            {{ $n(data.item.pump_running_time) }}
                                        </template>
                                        <template v-slot:cell(irrigation_area)="data">
                                            {{ $n(data.item.irrigation_area) }}
                                        </template>
                                        <template v-slot:cell(created_at)="data">
                                            <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFromNow }}</span>
                                        </template>
                                        <template v-slot:cell(task_status)="data">
                                            {{ data.item.task_status === 1 ? $t('globalTrans.pending') : (data.item.task_status === 2 ? $t('globalTrans.submitted') : 'N/A') }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button-group>
                                                <b-button v-if="data.item.task_status === 1" variant="light" size="sm" v-b-modal.modal-4 @click="update(data.item)">{{  $t('globalTrans.update') }}</b-button>
                                            </b-button-group>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="dataChange"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
            <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <FormV :item="currentItem" :key="resetId" @submitted="dataChange"/>
            </b-modal>
        </b-row>
    </b-container>
</template>

<script>
import FormV from './Form'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerAssignTaskList } from '../../../api/routes'
import { Common, List } from '@/mixins/helper-functions'
import FormSearch from '@/components/structure/form/search/FormSearch'

let flag = false
export default {
    name: 'DailyTaskList',
    mixins: [List, Common],
    components: {
        FormV, FormSearch // , EditForm
    },
    data () {
        return {
            search: {
                task_date: ''
            },
            resetId: 0,
            taskId: 0,
            currentItem: {},
            rows: [],
            defaultNull: '',
            listData: [],
            isAllowed: true
        }
    },
    computed: {
        searchAttributes () {
            const vm = this
            return {
                buttonText: this.$t('globalTrans.search'),
                data: [
                    {
                        name: 'from_date',
                        type: 'Datepicker',
                        label: 'warehouseFarmer.form_date',
                        config: {
                            onChange: function (selectedDates, dateStr) {
                                vm.search.task_date = dateStr
                            }
                        }
                    },
                    {
                        name: 'to_date',
                        type: 'Datepicker',
                        label: 'warehouseFarmer.to_date',
                        config: {
                            onChange: function (selectedDates, dateStr) {
                                vm.search.task_date = dateStr
                            }
                        }
                    }
                ]
            }
        },
        formTitle () {
            return this.$t('external_sidebar.my_daily_task') + ' ' + this.$t('globalTrans.entry')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('warehouseFarmer.meterReadingBefore'), class: 'text-left' },
                { label: this.$t('warehouseFarmer.meterReadingAfter'), class: 'text-left' },
                { label: this.$t('warehouseFarmer.pumpRunnigTime'), class: 'text-left' },
                { label: this.$t('warehouseFarmer.irriArea'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('warehouseFarmer.date'), class: 'text-left' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            var keys = [
                { key: 'index' },
                { key: 'meter_reading_before' },
                { key: 'meter_reading_after' },
                { key: 'pump_running_time' },
                { key: 'irrigation_area' },
                { key: 'task_status' },
                { key: 'created_at' },
                { key: 'action' }
            ]
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
        loadingState (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        }
    },
    async created () {
        this.checkAccess()
        flag = true
        this.loadData()
        flag = false
    },
    methods: {
        checkAccess () {
            const opt = this.$store.state.ExternalUserIrrigation.pumpOptDetails
            if (opt) {
                this.isAllowed = opt.daily_task_entry_required !== false
            }
        },
        update (item) {
            this.currentItem = item
        },
        dataChange () {
            flag = true
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                editable: false
            }
        },
        searchData (payload) {
            this.search = payload
            flag = true
            this.loadData()
        },
        loadData () {
            if (!flag) {
                return
            }
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage })
            RestApi.getData(irriSchemeServiceBaseUrl, farmerAssignTaskList, params)
                .then(async (response) => {
                    if (response.success) {
                        this.listData = response.data.data
                    }
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                })
                .catch((error) => {
                if (error.response) {
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                }
            })
        }
    }
}
</script>
