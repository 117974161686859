<template>
    <b-container fluid>
        <b-row v-if="isAllowed">
            <iq-card>
                <template v-slot:body>
                    <b-row>
                        <b-col lg="12" sm="12">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                <b-form @submit.prevent="handleSubmit(saveTaskReport)" @reset.prevent="reset"
                                        enctype="multipart/form-data">
                                    <b-row>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider :name="$t('warehouseFarmer.meterReadingBefore')" vid="meter_reading_before"
                                                                rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="meter_reading_before"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label> {{ $t('warehouseFarmer.meterReadingBefore') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        plain
                                                        v-model="assignTask.meter_reading_before"
                                                        id="meter_reading_before"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        type="number"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider :name="$t('warehouseFarmer.meterReadingAfter')" vid="meter_reading_after"
                                                                rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="meter_reading_after"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label> {{ $t('warehouseFarmer.meterReadingAfter') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        plain
                                                        v-model="assignTask.meter_reading_after"
                                                        id="meter_reading_after"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        type="number"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider :name="$t('warehouseFarmer.pumpRunnigTime')" vid="pump_running_time"
                                                                rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="pump_running_time"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label> {{ $t('warehouseFarmer.pumpRunnigTime') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        plain
                                                        v-model="assignTask.pump_running_time"
                                                        id="pump_running_time"
                                                        :options="task_status_list"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        type="number"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider :name="$t('warehouseFarmer.irriArea')" vid="irrigation_area"
                                                                rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="irrigation_area"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label> {{ $t('warehouseFarmer.irriArea') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        plain
                                                        v-model="assignTask.irrigation_area"
                                                        id="irrigation_area"
                                                        :options="task_status_list"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        type="number"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider name="Working Date" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="date"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('warehouseFarmer.workingDate') }} <span class="text-danger">*</span><br>
                                                        <small class="text-muted">({{ $t('globalTrans.multipleEntryAllowed') }})</small>
                                                    </template>
                                                    <b-form-input
                                                        plain
                                                        id="working_date"
                                                        class="date-picker"
                                                        v-model="assignTask.working_date"
                                                        placeholder="Select Date"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider name="Date">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="date"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label> {{ $t('warehouseFarmer.submissionDate') }} <span
                                                        class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        plain
                                                        v-model="assignTask.task_date"
                                                        id="irrigation_area"
                                                        class="date-picker"
                                                        :options="task_status_list"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        disabled
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <div class="row">
                                        <div class="col-sm-3"></div>
                                        <div class="col text-right">
                                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </template>
            </iq-card>
        </b-row>
       <b-row v-else>
            <div class="box-message col-md-12">
                <div class="message-body">
                    <p class="pb-2 pt-2"><i class="ri-alert-fill mr-2"></i>{{ $t('externalUserIrrigation.noPrivilegeFeatureMessage') }}</p>
                </div>
            </div>
        </b-row>
    </b-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// import { farmerAssignTaskStore, taskReportUpdate } from '@/modules/irrigation-scheme-service/task/api/routes'
import { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { Common, Repository } from '@/mixins/helper-functions'
import flatpickr from 'flatpickr'
import { core } from '@/config/pluginInit'
import { farmerAssignTaskStore } from '../../../api/routes'

export default {
    name: 'DailyTaskForm',
    mixins: [Common],
    props: ['item'],
    components: {
    ValidationObserver,
    ValidationProvider
    },
    data () {
        return {
            saveBtnName: this.item.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            assignTask: {
                meter_reading_before: '',
                meter_reading_after: '',
                pump_running_time: '',
                irrigation_area: '',
                task_date: '',
                currentDate: '',
                working_date: '',
                org_id: this.$store.state.Auth.authUser.org_id
            },
            defaultNull: '',
            isAllowed: true
        }
    },
    computed: {
        task_status_list () {
            return this.$store.state.IrriTask.taskReportStatusList
        }
    },
    methods: {
        checkAccess () {
            const opt = this.$store.state.ExternalUserIrrigation.pumpOptDetails
            if (opt) {
                this.isAllowed = opt.daily_task_entry_required
            }
        },
        async saveTaskReport () {
            if (this.item.id) {
                Object.assign(this.assignTask, { id: this.item.id })
            }
            const result = await Repository.create({
                data: this.assignTask,
                baseURL: irriSchemeServiceBaseUrl,
                storeAPI: farmerAssignTaskStore,
                modalId: 'task-report'
            })

            if (result.success) {
                this.$bvModal.hide('modal-4')
                this.$emit('submitted')
            }
        }
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
        const today = new Date()
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
        this.assignTask.task_date = date
    },
    created () {
        this.checkAccess()
        // this.isAllowed = this.$store.state.Auth.authUser.is_org_admin
        Object.assign(this.assignTask, this.item)
    }
}
</script>

<style scoped>

</style>
